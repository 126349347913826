<p class="label m-0 max-w-[160px]">
  {{ valueName() }}
</p>
<lib-icon
  class="w-24 h-24 right-2 cursor-pointer arrow-rotate"
  [class.opened]="view()"
  name="dropdown-arrow"
  (click)="toggle()"
></lib-icon>
<div
  *ngIf="view()"
  class="fixed top-0 left-0 right-0 bottom-0 z-[2000]"
  (click)="toggle()"
></div>
<div class="input-container flex-col gap-4" *ngIf="view()">
  <div
    class="flex w-full items-center justify-between cursor-pointer"
    *ngFor="let item of items()"
    (click)="changeVal(item.key)"
  >
    <p class="my-0 text-15 font-normal mr-4">
      {{ item.value }}
    </p>
  </div>
</div>
