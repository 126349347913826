import { createSelector } from '@ngrx/store';

export const profile = 'profile';
export const notifications = 'notifications';

export const profileFeature = (state: any) => state[profile];

export const selectProfile = createSelector(
  profileFeature,
  (state: any) => state,
);

export const notificationsFeature = (state: any) => state[notifications];
export const selectNotifications = createSelector(
  notificationsFeature,
  (state) => state,
);
export const selectProfileCompanies = createSelector(
  selectProfile,
  ({ companies }: any) => companies ?? [],
);
export const selectProfileActiveCompany = createSelector(
  selectProfile,
  ({ activeCompanyId }: any) => activeCompanyId,
);
export const selectProfileEntity = createSelector(
  selectProfile,
  ({ entity }: any) => entity,
);
