import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IPagination } from '@common/interfaces';

@Component({
  selector: 'app-pagination[pagination]',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  @Input()
  public pagination!: IPagination | null;

  @Output()
  public readonly changePageEvent = new EventEmitter<number>();

  public get currentPage(): number {
    return (this.pagination?.currentPage ?? 0) + 1;
  }

  public get pages(): any[] {
    const pages = [...new Array(this.pagination?.pageCount).keys()].map(
      (key: number) => key + 1,
    );
    if (pages.length <= 20) {
      return pages.map((val) =>
        typeof val === 'number' ? { label: val, value: val } : val,
      );
    }
    if (this.currentPage <= 5) {
      return [
        ...pages.slice(0, 5),
        { label: '...', isSystem: true },
        ...pages.slice(-1),
      ].map((val) =>
        typeof val === 'number' ? { label: val, value: val } : val,
      );
    } else if (this.currentPage >= pages.length - 5) {
      return [
        ...pages.slice(0, 1),
        { label: '...', isSystem: true },
        ...pages.slice(-5),
      ].map((val) =>
        typeof val === 'number' ? { label: val, value: val } : val,
      );
    }
    const index = pages.findIndex((item) => item === this.currentPage + 1);
    return [
      ...pages.slice(0, 1),
      { label: '...', isSystem: true },
      ...pages.slice(index - 2, index + 2),
      { label: '...', isSystem: true },
      ...pages.slice(-1),
    ].map((val) =>
      typeof val === 'number' ? { label: val, value: val } : val,
    );
  }

  public trackByFn(index: number, item: any): number {
    return index;
  }

  public select(page: any): void {
    if (page.isSystem || page?.value === this.currentPage) {
      return;
    }
    this.changePageEvent.emit(page.value);
  }
}
