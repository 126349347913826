<div class="flex flex-col basis-[460px] shrink-0 min-w-[460px] mr-48">
  <div class="flex">
    <div class="flex h-[52px] w-full mt-16">
      <hr class="border-0 p-0 w-[1px] h-full bg-black mr-24" />
      <div class="flex w-[248px] gap-[24px]">
        <div class="flex flex-1 flex-col justify-between">
          <p class="m-0 text-15 leading-20 black-half-color">Всего платежей</p>
          <p class="m-0 text-15 leading-20 font-bold">{{ totalCount }}</p>
        </div>
        <div class="flex flex-1 flex-col justify-between">
          <p class="m-0 text-15 leading-20 black-half-color">Дост. платежей</p>
          <p class="m-0 text-15 leading-20 font-bold">{{ completedCount }}</p>
        </div>
      </div>
      <hr class="border-0 p-0 w-[1px] h-full bg-black mx-24" />
      <div class="flex w-[228px] gap-[24px]">
        <div class="flex flex-1 flex-col justify-between">
          <p class="m-0 text-15 leading-20 black-half-color">Общая сумма</p>
          <p class="m-0 text-15 leading-20 font-bold">
            @if (amountTotal !== null) {
              {{ amountTotal | currency: "KZT" : "symbol-narrow" : "0.2-2" }}
            } @else {
              {{ "-" }}
            }
          </p>
        </div>
        <div class="flex flex-1 flex-col justify-between">
          <p class="m-0 text-15 leading-20 black-half-color">Доставлено</p>
          <p class="m-0 text-15 leading-20 font-bold">
            @if (amountCompleted !== null) {
              {{
                amountCompleted | currency: "KZT" : "symbol-narrow" : "0.2-2"
              }}
            } @else {
              {{ "-" }}
            }
          </p>
        </div>
      </div>
      <hr class="border-0 p-0 w-[1px] h-full bg-black ml-24" />
      <lib-icon
        name="statistic-arrow"
        [class.rotate-180]="view()"
        class="ml-8 cursor-pointer"
        (click)="toggle()"
      ></lib-icon>
    </div>
  </div>
  <div class="flex flex-col mt-[28px]" *ngIf="view()">
    <div class="flex">
      <div class="flex flex-col justify-between w-[296px]">
        <p class="m-0 text-15 leading-20 black-half-color">Диапазон</p>
        <p class="m-0 text-15 leading-20 font-bold">
          @if (amountMin !== null && amountMax !== null) {
            от&nbsp;<span>{{
              amountMin | currency: "KZT" : "symbol-narrow" : "0.2-2"
            }}</span
            >&nbsp;до&nbsp;<span>{{
              amountMax | currency: "KZT" : "symbol-narrow" : "0.2-2"
            }}</span>
          } @else {
            {{ "-" }}
          }
        </p>
      </div>
      <div class="flex box-border px-24 w-[276px] gap-[24px]">
        <div class="flex flex-1 flex-col justify-between">
          <p class="m-0 text-15 leading-20 black-half-color">Налогов</p>
          <p class="m-0 text-15 leading-20 font-normal">
            <!--            @if (taxTotal !== null) {-->
            <!--              {{ taxTotal | currency: "KZT" : "symbol-narrow" : "0.2-2" }}-->
            <!--            } @else {-->
            <!--              {{ "-" }}-->
            <!--            }-->
            -
          </p>
        </div>
        <div class="flex flex-1 flex-col justify-between">
          <p class="m-0 text-15 leading-20 black-half-color">Комиссия</p>
          <p class="m-0 text-15 leading-20 font-normal">
            <!--            @if (feeTotal !== null) {-->
            <!--              {{ feeTotal | currency: "KZT" : "symbol-narrow" : "0.2-2" }}-->
            <!--            } @else {-->
            <!--              {{ "-" }}-->
            <!--            }-->
            -
          </p>
        </div>
      </div>
    </div>
    <hr class="border-0 p-0 w-full h-[2px] bg-black mt-[28px]" />
  </div>
</div>
