import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { CurrencyPipe, NgIf } from '@angular/common';
import { LibIconComponent } from '@common/components';

@Component({
  selector: 'app-statistic-data',
  templateUrl: './statistic-data.component.html',
  styleUrls: ['./statistic-data.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CurrencyPipe, LibIconComponent, NgIf],
})
export class StatisticDataComponent {
  public readonly view = signal(false);

  @Input()
  public data?: any;

  public get totalCount(): string | number {
    return this.data?.moneyTransfer.total?.count ?? '-';
  }
  public get completedCount(): string | number {
    return this.data?.moneyTransfer.completed?.count ?? '-';
  }

  public get amountTotal() {
    return this?.data?.moneyTransfer?.total?.sum ?? null;
  }

  public get amountCompleted() {
    return this.data?.moneyTransfer.completed?.sum ?? null;
  }

  public get amountMin() {
    return this?.data?.moneyTransfer?.sumRange?.min ?? null;
  }

  public get amountMax() {
    return this?.data?.moneyTransfer?.sumRange?.max ?? null;
  }

  public get taxTotal() {
    return this?.data?.tax?.sum ?? null;
  }

  public get feeTotal() {
    return this?.data?.fee?.sum ?? null;
  }

  public toggle(): void {
    const view = this.view();
    this.view.set(!view);
  }
}

/*

mount": {
    "all": 10000,
    "min": 1000,
    "max": 3000
  }

 */
