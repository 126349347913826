import { PaymentStatusesEnum } from '@common/enums';

export const paymentDetailStatusesMeta = new Map([
  [
    PaymentStatusesEnum.Loaded,
    {
      label: 'Загружен',
      color: '',
    },
  ],
  [
    PaymentStatusesEnum.SentToBank,
    {
      label: 'Отправлено',
      color: 'dark-green-color',
    },
  ],
  [
    PaymentStatusesEnum.isProcess,
    {
      label: 'На исполнении',
      color: 'violet-color',
    },
  ],
  [
    PaymentStatusesEnum.PartiallyCompleted,
    {
      label: 'Доставлено',
      color: 'green-color',
    },
  ],
  [
    PaymentStatusesEnum.fullyCompleted,
    {
      label: 'Доставлено',
      color: 'green-color',
    },
  ],
  [
    PaymentStatusesEnum.Error,
    {
      label: 'Ошибка',
      color: 'red-color',
    },
  ],
  [
    PaymentStatusesEnum.Warning,
    {
      label: 'Требуется проверка',
      color: 'yellow-color',
    },
  ],
  [
    PaymentStatusesEnum.Completed,
    {
      label: 'Завершено',
      color: 'green-color',
    },
  ],
]);
