import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { LibIconComponent } from '@common/components';
import { NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
  selector: 'lib-text-control',
  templateUrl: './text-control.component.html',
  styleUrls: ['./text-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextControlComponent),
      multi: true,
    },
  ],
  imports: [LibIconComponent, NgIf, ReactiveFormsModule],
})
export class TextControlComponent implements ControlValueAccessor, OnInit {
  private readonly destroyRef = inject(DestroyRef);
  public readonly control = new FormControl<string | null>(null);

  @Input()
  public placeholder?: string;

  @Input()
  public icon?: string;

  @HostBinding('class.with-icon')
  public get withIcon(): boolean {
    return !!this.icon;
  }

  public writeValue(val: string): void {
    this.control.patchValue(val, { emitEvent: false });
  }

  public ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        tap((val) => this.onChange(val)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onChange = (_: string | null) => {};

  onTouched = () => {};

  public registerOnChange(onChange: typeof this.onChange): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: typeof this.onTouched): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }
}
