import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { KeyValue, NgClass, NgForOf } from '@angular/common';
import {
  TabBarActiveLineDirective,
  TabBarIsActiveButtonDirective,
} from '@common/directives';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    TabBarIsActiveButtonDirective,
    TabBarActiveLineDirective,
  ],
})
export class TabBarComponent<T extends string> implements AfterViewInit {
  private readonly elementRef = inject(ElementRef<HTMLElement>);

  @Output()
  public readonly onChangeTab = new EventEmitter<T>();

  @Input()
  public list: KeyValue<T, string>[] | ReadonlyArray<KeyValue<T, string>> = [];

  public readonly active = signal<T | null>(null);
  public readonly position = signal<any>({
    button: null,
    parent: null,
  });

  public ngAfterViewInit(): void {
    if (this.list[0]) {
      this.activate(this.list[0]?.key);
    }
  }

  public activePositionHandler($event: DOMRect): void {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    this.position.set({
      button: $event,
      parent: rect,
    });
  }

  public activate(key: T): void {
    this.active.set(key);
    this.onChangeTab.emit(key);
  }

  public trackByFn(_index: number, item: KeyValue<string, string>): string {
    return item.key;
  }
}
