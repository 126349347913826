import { RegistryStatusesEnum } from '@common/enums';

export const registryStatusesMeta = new Map([
  [RegistryStatusesEnum.Loaded, { label: 'Загружен', color: '' }],
  [RegistryStatusesEnum.SentToEdo, { label: 'Отправлено в ЭДО', color: '' }],
  [
    RegistryStatusesEnum.isProcess,
    { label: 'Отправлено исполнителям', color: '' },
  ],
  [
    RegistryStatusesEnum.PartiallyCompleted,
    { label: 'Подписан частью исполнителей', color: '' },
  ],
  [
    RegistryStatusesEnum.fullyCompleted,
    { label: 'Готово: подписано всеми исполнителями', color: '' },
  ],
  [RegistryStatusesEnum.Error, { label: 'Ошибка', color: '' }],
]);
