import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-account-data',
  templateUrl: './account-data.component.html',
  styleUrls: ['./account-data.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CurrencyPipe],
})
export class AccountDataComponent {
  @Input()
  public data?: any;

  public get account() {
    return this.data?.number || '-';
  }
  public get amount() {
    return this.data?.amount ?? null;
  }
  public get withdrawalForToday() {
    return this.data?.withdrawal?.forToday ?? null;
  }
  public get withdrawalForWeek() {
    return this.data?.withdrawal?.forWeek ?? null;
  }

  public get depositForToday() {
    return this.data?.deposit?.forToday ?? null;
  }
  public get depositForWeek() {
    return this.data?.deposit?.forWeek ?? null;
  }
}
