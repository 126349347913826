import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { LibIconComponent } from '@common/components';
import { NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { maskitoDateRangeOptionsGenerator } from '@maskito/kit';
import { MaskitoDirective } from '@maskito/angular';

@Component({
  selector: 'lib-date-range-control',
  templateUrl: './date-range-control.component.html',
  styleUrls: ['./date-range-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeControlComponent),
      multi: true,
    },
  ],
  imports: [LibIconComponent, NgIf, ReactiveFormsModule, MaskitoDirective],
})
export class DateRangeControlComponent implements ControlValueAccessor, OnInit {
  private readonly destroyRef = inject(DestroyRef);
  public readonly control = new FormControl<string | null>(null);
  public view = signal(false);

  @Input()
  public placeholder?: string;

  public readonly mask = maskitoDateRangeOptionsGenerator({
    mode: 'dd/mm/yyyy',
    dateSeparator: '.',
  });

  public get value(): string {
    const list = (this.control.value ?? '').split(' ');
    const [rawFrom, separator, rawTo] = list;
    let from = '';
    let to = '';
    if (/^\d{2}\.\d{2}\.\d{4}/.test(rawFrom)) {
      const [days, month, year] = rawFrom.split('.');
      from = `${days}.${month}.${year.slice(2)}`;
    }
    if (/^\d{2}\.\d{2}\.\d{4}/.test(rawTo)) {
      const [days, month, year] = rawTo.split('.');
      to = `${days}.${month}.${year.slice(2)}`;
    }
    if (!from || !to) {
      return 'Все время';
    }
    return `${from} - ${to}`;
  }

  public writeValue(val: any): void {
    // this.control.patchValue(val, { emitEvent: false });
  }

  public ngOnInit(): void {}

  public submit(): void {
    const list = (this.control.value ?? '').split(' ');
    const [from, separator, to] = list;
    if (/^\d{2}\.\d{2}\.\d{4}/.test(from) && /^\d{2}\.\d{2}\.\d{4}/.test(to)) {
      this.onChange({
        from,
        to,
      });
    } else {
      this.onChange(null);
    }
    this.toggle();
  }

  public toggle(): void {
    const val = this.view();
    this.view.set(!val);
  }

  onChange = (_: any | null) => {};

  onTouched = () => {};

  public registerOnChange(onChange: typeof this.onChange): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: typeof this.onTouched): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }
}
