import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LetContext } from './let-context';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngLet]',
    standalone: true,
})
export class LetDirective<T> {
    @Input()
    public ngLet?: T;

    constructor(@Inject(ViewContainerRef) viewContainer: ViewContainerRef, @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>) {
        viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
    }
}
