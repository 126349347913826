import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  NgZone,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationRemove } from '../../../cabinet.page/store';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NotificationMessageComponent {
  private readonly ngZone = inject(NgZone);
  private readonly store = inject(Store);
  private timer: any;

  public value: any;

  @Input()
  public set data(value: any) {
    this.value = value;
    this.startTimer();
  }

  @Input()
  public index!: number;

  public get message(): string {
    if (this.value.error && this.value.error instanceof HttpErrorResponse) {
      const { error } = this.value.error;
      return error.message || error.messages.join(', ');
    }
    return (
      this.value?.message ||
      this.value?.error?.message ||
      this.value?.error?.messages?.join(', ')
    );
  }

  public startTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.ngZone.runOutsideAngular(
      () =>
        (this.timer = setTimeout(
          () => this.store.dispatch(NotificationRemove({ index: this.index })),
          3000,
        )),
    );
  }
}
