import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { convertUtil } from '@common/utils';

@Injectable({
  providedIn: 'root',
})
export class ProfileApiService {
  private readonly http = inject(HttpClient);

  public load() {
    return this.http.get('/v2/me').pipe(map((res) => convertUtil(res)));
  }

  public loadEntity() {
    return this.http.get('/v2/entity');
  }

  public companies() {
    return this.http.get('/v2/me/companies');
  }

  public changeCompany(body: any) {
    return this.http.patch(`/v2/me`, convertUtil(body, 'snake_case'));
  }
}
