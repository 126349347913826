@for (page of pages; track trackByFn) {
  <p
    class="mx-4 leading-24 text-18 my-0 cursor-pointer"
    [class.black-color]="currentPage === page?.value"
    [class.black-half-color]="currentPage !== page?.value"
    (click)="select(page)"
  >
    {{ page.label }}
  </p>
}
