import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[tabBarIsActiveButton]',
  standalone: true,
})
export class TabBarIsActiveButtonDirective implements OnChanges {
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  private readonly ngZone = inject(NgZone);

  @HostBinding('class.active')
  public isActive: boolean = false;

  @Input()
  public tabBarIsActiveButton?: string;

  @Input()
  public key?: string | null;

  @Output()
  public activePosition: EventEmitter<DOMRect> = new EventEmitter<DOMRect>();

  @Output()
  public onClick: EventEmitter<DOMRect> = new EventEmitter<DOMRect>();

  @HostListener('click')
  public clickEvent(): void {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    if (rect && !rect.width) {
      this.ngZone.runOutsideAngular(() =>
        setTimeout(() => {
          this.onClick.emit(
            this.elementRef.nativeElement.getBoundingClientRect(),
          );
        }, 0),
      );
    } else {
      this.onClick.emit(rect);
    }
  }

  public ngOnChanges(_changes: SimpleChanges): void {
    this.isActive = this.key === this.tabBarIsActiveButton;
    if (this.isActive) {
      const rect = this.elementRef.nativeElement.getBoundingClientRect();
      if (rect && !rect.width) {
        this.ngZone.runOutsideAngular(() =>
          setTimeout(() => {
            this.activePosition.emit(
              this.elementRef.nativeElement.getBoundingClientRect(),
            );
          }, 0),
        );
      } else {
        this.activePosition.emit(rect);
      }
    }
  }
}
