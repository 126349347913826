import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LibIconsRegistryService } from '@common/services';

@Component({
  selector: 'lib-icon',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./lib-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LibIconComponent {
  private svgIcon!: SVGElement;

  @Input()
  public set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.iconRegistryService.getIcon(iconName);
    if (!svgData) {
      return;
    }
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  constructor(
    private element: ElementRef,
    private iconRegistryService: LibIconsRegistryService,
    @Optional() @Inject(DOCUMENT) private document: Document,
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return (div.querySelector('svg') ||
      this.document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
      )) as SVGElement;
  }
}
