export enum PaymentStatusesEnum {
  Loaded = 'loaded',
  SentToBank = 'sent_to_bank',
  isProcess = 'is_process',
  PartiallyCompleted = 'partially_completed',
  fullyCompleted = 'fully_completed',
  Error = 'error',
  Warning = 'warning',
  Completed = 'completed',
}
