import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { LoaderElementComponent } from '@common/components';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [forwardRef(() => LoaderElementComponent)],
})
export class LoaderComponent {}
