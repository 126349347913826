import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoriesApiService {
  private readonly http = inject(HttpClient);

  public getPayments(search?: any): Observable<any> {
    let url = '/v2/payments';
    const params = new URLSearchParams();
    if (search) {
      Object.keys(search).forEach((key: string) =>
        params.append(key, search[key]?.toString()),
      );
      url += '?' + params.toString();
    }
    return this.http.get<any>(url);
  }

  public getPaymentsFile(search?: any): Observable<any> {
    let url = '/v2/payments/report/file';
    const params = new URLSearchParams();
    if (search) {
      Object.keys(search).forEach((key: string) =>
        params.append(key, search[key]?.toString()),
      );
      url += '?' + params.toString();
    }
    return this.http.get(url, { responseType: 'blob' });
  }
}
