import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ICouriersResponse } from '@common/interfaces';
import { PAGINATION_DEFAULT_LIMIT } from '@common/config';

@Injectable({
  providedIn: 'root',
})
export class CouriersApiService {
  private readonly http = inject(HttpClient);

  public loadCouriers(
    page: number = 1,
    search: any = {},
  ): Observable<ICouriersResponse> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    if (search) {
      Object.entries(search ?? {}).map(([key, value]: any) =>
        params.append(key, value.toString()),
      );
    }
    return this.http.get<ICouriersResponse>(
      '/v2/couriers?' + params.toString(),
    );
  }

  public upload(file: Blob) {
    const form = new FormData();
    form.append('file', file);
    return this.http.post('/v2/performers/registry/file', form, {
      reportProgress: true,
      observe: 'events',
      headers: {
        responseType: 'json',
      },
    });
  }

  public loadCourierTables(id: number) {
    return this.http.get(`/v2/couriers/${id}/history`);
  }

  public loadCourier(id: number) {
    return this.http.get(`/v2/couriers/${id}`);
  }

  public courierUpdate(id: number, data: any) {
    return this.http.patch(`/v2/couriers/${id}`, data);
  }
}
