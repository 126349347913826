import {
  camelCase,
  isArray,
  isNull,
  isObject,
  isUndefined,
  snakeCase,
} from 'lodash';

export function convertUtil(
  data: unknown,
  type: 'camelCase' | 'snake_case' = 'camelCase',
): unknown {
  const fn = type === 'camelCase' ? camelCase : snakeCase;
  if (isNull(data) || isUndefined(data)) {
    return data;
  }
  if (isArray(data)) {
    return [...data.map((value) => convertUtil(value, type))];
  }
  if (isObject(data)) {
    const newData: Record<string, unknown> = {};
    Object.entries(data).forEach(
      ([key, value]: [string, unknown]) =>
        (newData[fn(key)] = convertUtil(value, type)),
    );
    return newData;
  }
  return data;
}
