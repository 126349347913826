import { createAction, props } from '@ngrx/store';

export const ProfileLoad = createAction('[Profile] Load');
export const ProfileLoadSuccess = createAction(
  '[Profile] Load Success',
  props<{ email: string; activeCompanyId: string; entity: any }>(),
);
export const ProfileLoadError = createAction('[Profile] Load Error');
export const ProfileReset = createAction('[Profile] Reset');

export const NotificationAdd = createAction(
  '[Notifications] Add',
  props<{ message: any }>(),
);
export const NotificationRemove = createAction(
  '[Notifications] Remove',
  props<{ index: number }>(),
);

export const ProfileLoadCompanies = createAction('[Profile] Load Companies');
export const ProfileLoadCompaniesSuccess = createAction(
  '[Profile] Load Companies Success',
  props<{ companies: any[] }>(),
);
export const ProfileLoadCompaniesError = createAction(
  '[Profile] Load Companies Error',
);
export const ProfileChangeCompany = createAction(
  '[Profile] Change Company',
  props<{ id: string }>(),
);
export const ProfileChangeCompanySuccess = createAction(
  '[Profile] Change Company Success',
);
export const ProfileChangeCompanyError = createAction(
  '[Profile] Change Company Error',
);
