import { PaymentStatusesEnum } from '@common/enums';

export const paymentStatusesMeta = new Map([
  [
    PaymentStatusesEnum.Loaded,
    {
      label: 'Загружен',
      color: '',
    },
  ],
  [
    PaymentStatusesEnum.SentToBank,
    {
      label: 'Отправлено в банк',
      color: '',
    },
  ],
  [
    PaymentStatusesEnum.isProcess,
    {
      label: 'На исполнении',
      color: 'violet-color',
    },
  ],
  [
    PaymentStatusesEnum.PartiallyCompleted,
    {
      label: 'Готово: частично исполнен',
      color: 'dark-green-color',
    },
  ],
  [
    PaymentStatusesEnum.fullyCompleted,
    {
      label: 'Готово: полностью исполнен',
      color: 'green-color',
    },
  ],
  [
    PaymentStatusesEnum.Error,
    {
      label: 'Ошибка',
      color: 'red-color',
    },
  ],
]);
