import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPaymentRegistryResponse } from '@common/interfaces';
import { catchError, Observable, throwError } from 'rxjs';
import { PAGINATION_DEFAULT_LIMIT } from '@common/config';

@Injectable({
  providedIn: 'root',
})
export class PaymentsApiService {
  private readonly http = inject(HttpClient);

  public loadPaymentsBankAccount(
    page: number = 1,
  ): Observable<IPaymentRegistryResponse> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    params.append('registryType', 'bank');
    return this.http.get<IPaymentRegistryResponse>(
      '/v2/legacy/registries?' + params.toString(),
    );
  }

  public loadPaymentsCards(
    page: number = 1,
  ): Observable<IPaymentRegistryResponse> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    params.append('registryType', 'card');
    return this.http.get<IPaymentRegistryResponse>(
      '/v2/legacy/registries?' + params.toString(),
    );
  }

  public loadPayments(page: number = 1): Observable<IPaymentRegistryResponse> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    return this.http.get<IPaymentRegistryResponse>(
      '/v2/registries?' + params.toString(),
    );
  }

  public loadDetailInfo(id: number): Observable<any> {
    return this.http.get(`/v2/payments/registry/${id}/info`);
  }

  public loadDetailContent(id: number, page: number = 1): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    return this.http.get(
      `/v2/payments/registry/${id}/content?` + params.toString(),
    );
  }

  public loadDetail(id: number, page: number = 1): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    return this.http.get(`/v2/registries/${id}/payments?` + params.toString());
  }

  public sendStart(email: string): Observable<any> {
    return this.http.post<any>('/verification/start/email', {
      email,
    });
  }

  public sendComplete(body: any) {
    return this.http
      .post('/verification/complete', body)
      .pipe(catchError(() => throwError(new Error('Некорректный код'))));
  }

  public sendAction(id: number, body: any) {
    return this.http
      .post(`/payments/registry/${id}/send`, body)
      .pipe(
        catchError(() => throwError(new Error('При отправке возникла ошибка'))),
      );
  }

  public upload(file: Blob) {
    const form = new FormData();
    form.append('file', file);
    return this.http.post('/v2/payments/registry/file', form, {
      reportProgress: true,
      observe: 'events',
      headers: {
        responseType: 'json',
      },
    });
  }

  public uploadValidate(file: Blob) {
    const form = new FormData();
    form.append('file', file);
    return this.http.post('/v2/payments/registry/validate', form, {
      headers: {
        responseType: 'json',
      },
    });
  }

  public deletePayments(id: string): Observable<void> {
    return this.http.delete<void>(`/v2/payments/registry/${id}`);
  }

  public history(search: any = {}): Observable<any> {
    let url = '/v2/payments/registry/history';
    if (search && Object.keys(search).length > 1) {
      const params = new URLSearchParams();
      Object.entries(search).forEach(([key, value]: any) =>
        params.append(key, value.toString()),
      );
      url += '?' + params.toString();
    }
    return this.http.get<any>(url);
  }
}
