import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { PAGINATION_DEFAULT_LIMIT } from '@common/config';

@Injectable({
  providedIn: 'root',
})
export class RegistriesApiService {
  private readonly http = inject(HttpClient);

  public loadRegistryAvr(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    return this.http.get('/act/registry/all?limit=1000&page=1');
  }

  public loadRegistryDetail(id: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    return this.http.get(`/act/registry/${id}?limit=1000&page=1`);
  }

  public upload(file: Blob) {
    const form = new FormData();
    form.append('file', file);
    return this.http.post('/v2/acts/registry/import', form, {
      reportProgress: true,
      observe: 'events',
      headers: {
        responseType: 'json',
      },
    });
  }

  public uploadDocs(file: Blob) {
    const form = new FormData();
    form.append('file', file);
    return this.http.post('/contract/registry/import', form, {
      reportProgress: true,
      observe: 'events',
      headers: {
        responseType: 'json',
      },
    });
  }

  public sendStart(email: string) {
    return this.http.post('/verification/start/email', {
      email,
    });
  }

  public sendComplete(body: any) {
    return this.http
      .post('/verification/complete', body)
      .pipe(catchError(() => throwError(new Error('Некорректный код'))));
  }

  public sendAction(id: number) {
    return this.http
      .post(`/act/registry/${id}/send`, null)
      .pipe(
        catchError(() => throwError(new Error('При отправке возникла ошибка'))),
      );
  }

  public signDocsRegistry(page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    return this.http.get('/contract/registry/all?' + params.toString());
  }

  public signDocsDetail(id: number, page: number = 1) {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('limit', PAGINATION_DEFAULT_LIMIT.toString());
    return this.http.get(`/contract/registry/${id}?` + params.toString());
  }

  public signDocsSendAction(id: number) {
    return this.http
      .post(`/contract/registry/${id}/send`, null)
      .pipe(
        catchError(() => throwError(new Error('При отправке возникла ошибка'))),
      );
  }
}
