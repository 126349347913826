<input
  type="text"
  class="preview cursor-pointer"
  [placeholder]="placeholder ?? ''"
  [value]="value"
  [readonly]="true"
  (click)="toggle()"
/>
<lib-icon
  class="w-16 h-16 absolute bottom-4 right-2 cursor-pointer arrow-rotate"
  [class.opened]="view()"
  name="arrow-down"
  (click)="toggle()"
></lib-icon>
<div
  *ngIf="view()"
  class="fixed top-0 left-0 right-0 bottom-0 z-[2000]"
  (click)="toggle()"
></div>
<div class="input-container" *ngIf="view()">
  <input
    type="text"
    class="border-none text-center h-20 text-14 bg-transparent w-full font-normal"
    placeholder="_ __ ____ - __ __ ____"
    [maskito]="mask"
    [formControl]="control"
    (keyup.enter)="submit()"
  />
</div>
