import { createReducer, on } from '@ngrx/store';
import {
  NotificationAdd,
  NotificationRemove,
  ProfileLoadCompaniesSuccess,
  ProfileLoadSuccess,
  ProfileReset,
} from './actions';
import { notifications, profile } from './selectors';

const profileInitialState: any = {
  email: null,
  activeCompanyId: null,
  companies: null,
  entity: null,
};

export const profileReducer = createReducer(
  profileInitialState,
  on(ProfileLoadSuccess, (state, { email, activeCompanyId, entity }) => ({
    ...state,
    email,
    activeCompanyId,
    entity,
  })),
  on(ProfileLoadCompaniesSuccess, (state, { companies }) => ({
    ...state,
    companies,
  })),
  on(ProfileReset, (state) => ({
    ...profileInitialState,
  })),
);

const notificationsInitialState: any = {
  messages: [],
};

export const notificationsReducer = createReducer(
  notificationsInitialState,
  on(NotificationAdd, (state, { message }) => ({
    ...state,
    messages: [message, ...state.messages],
  })),
  on(NotificationRemove, (state, { index }) => ({
    ...state,
    messages: notificationRemoveInList(state.messages, index),
  })),
);

function notificationRemoveInList(messages: any[], index: number): any[] {
  const arr = [...messages];
  arr.splice(index, 1);
  return arr;
}

export const cabinetReducers = {
  [profile]: profileReducer,
  [notifications]: notificationsReducer,
};
