import {
  Directive,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import { selectProfileEntity } from '../../cabinet.page/store';
import { get } from 'lodash';

@Directive({
  selector: '[hasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnChanges, OnDestroy {
  private readonly store = inject(Store);
  private readonly templateRef: TemplateRef<unknown> = inject(TemplateRef);
  private readonly vcr = inject(ViewContainerRef);
  private link!: Subscription;

  @Input('hasPermission')
  public scope?: string;

  @Input('hasPermissionAction')
  public action?: string;

  public ngOnChanges(): void {
    if (!this.scope || !this.action) {
      this.vcr.clear();
      return;
    }

    this.destroyLink();
    this.link = this.store
      .select(selectProfileEntity)
      .pipe(tap(({ pages }) => this.displayTemplate(pages)))
      .subscribe();
  }

  private displayTemplate(pages: any): void {
    const settings = get(pages, this.scope ?? '');
    this.vcr.clear();
    if (!settings) {
      return;
    }
    const hasAction = (settings.actions ?? []).includes(this.action);
    if (hasAction) {
      this.vcr.createEmbeddedView(this.templateRef);
    }
  }

  public ngOnDestroy(): void {
    this.destroyLink();
  }

  private destroyLink(): void {
    if (this.link && !this.link.closed) {
      this.link.unsubscribe();
    }
  }
}
