import {
  ChangeDetectionStrategy,
  Component,
  computed,
  forwardRef,
  Input,
  OnChanges,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { LibIconComponent } from '@common/components';
import { KeyValue, NgForOf, NgIf } from '@angular/common';
import { MaskitoDirective } from '@maskito/angular';
import { v4 as uuid } from 'uuid';
import { LetDirective } from '@common/directives';

@Component({
  selector: 'lib-dropdown-control',
  templateUrl: './dropdown-control.component.html',
  styleUrls: ['./dropdown-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownControlComponent),
      multi: true,
    },
  ],
  imports: [
    LibIconComponent,
    NgIf,
    ReactiveFormsModule,
    MaskitoDirective,
    NgForOf,
    LetDirective,
  ],
})
export class DropdownControlComponent implements ControlValueAccessor {
  public readonly id = uuid();
  public readonly value = signal<null | string>(null);
  public readonly items = signal<KeyValue<string | null, string>[]>([]);
  public readonly valueName = computed(() => {
    const value = this.value();
    const list = this.items();
    if (!value) {
      return 'Не выбрано';
    }
    const item = list.find((elem) => elem.key === value);
    if (!item) {
      return 'Не выбрано';
    }
    return item?.value;
  });

  public view = signal(false);

  @Input()
  public set list(val: KeyValue<string | null, string>[]) {
    this.items.set(val ?? []);
  }

  public writeValue(val: any): void {
    this.value.set(val);
  }

  public changeVal(key: string | null): void {
    this.value.set(key);
    this.view.set(false);
    this.onChange(key);
  }

  public toggle(): void {
    const val = this.view();
    this.view.set(!val);
  }

  onChange = (_: any | null) => {};

  onTouched = () => {};

  public registerOnChange(onChange: typeof this.onChange): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: typeof this.onTouched): void {
    this.onTouched = onTouched;
  }
}
