import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectNotifications } from '../../../cabinet.page/store';
import { NotificationMessageComponent } from '@common/components';

@Component({
  selector: 'app-notification-wrapper',
  templateUrl: './notification-wrapper.component.html',
  styleUrls: ['./notification-wrapper.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    forwardRef(() => NotificationMessageComponent),
    NotificationMessageComponent,
  ],
})
export class NotificationWrapperComponent {
  private readonly store = inject(Store);
  public readonly list = this.store.selectSignal<any>(selectNotifications);

  public trackByFn(index: number, item: any): number {
    return index;
  }
}
