<p class="label" [class.active]="labelActive()">{{ label }}</p>
<ng-content></ng-content>
<lib-button
  *ngIf="submitButton"
  class="submit-button ml-4"
  [label]="submitLabel"
  [disabled]="submitDisabled"
  [loading]="submitLoading"
  (onClick)="submit()"
></lib-button>
