import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgreementsApiService {
  private readonly http = inject(HttpClient);

  public agreementsLoad(page: number = 1): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    return this.http.get<any>(`/v2/agreements?${params.toString()}`);
  }
}
