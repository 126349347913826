<div class="flex w-[488px] flex-col">
  <p class="m-0 leading-20 text-15">Счет</p>
  <div class="flex justify-between mb-12">
    <p class="m-0 leading-20 text-15">{{ account }}</p>
    <p class="m-0 leading-20 text-15 font-medium">
      @if (amount !== null) {
        {{ amount | currency: "KZT" : "symbol-narrow" : "0.2-2" }}
      } @else {
        {{ "-" }}
      }
    </p>
  </div>
  <hr class="w-full m-0 border-0 p-0 bg-black h-2" />
  <div class="flex mt-20">
    <div class="flex-1"></div>
    <div class="flex-1 flex justify-end">
      <p class="m-0 leading-20 text-15 gray-color">Списания</p>
    </div>
    <div class="flex-1 flex justify-end">
      <p class="m-0 leading-20 text-15 gray-color">Зачисления</p>
    </div>
  </div>
  <div class="flex mt-8 br-gray">
    <div class="flex-1">
      <p class="m-0 leading-20 text-15 gray-color">Сегодня</p>
    </div>
    <div class="flex-1 flex justify-end">
      <p class="m-0 leading-20 text-15 black-color">
        @if (withdrawalForToday !== null) {
          {{ withdrawalForToday | currency: "KZT" : "symbol-narrow" : "0.2-2" }}
        } @else {
          {{ "-" }}
        }
      </p>
    </div>
    <div class="flex-1 flex justify-end">
      <p class="m-0 leading-20 text-15 black-color">
        @if (depositForToday !== null) {
          {{ depositForToday | currency: "KZT" : "symbol-narrow" : "0.2-2" }}
        } @else {
          {{ "-" }}
        }
      </p>
    </div>
  </div>
  <div class="flex mt-20 br-gray">
    <div class="flex-1">
      <p class="m-0 leading-20 text-15 gray-color">За неделю</p>
    </div>
    <div class="flex-1 flex justify-end">
      <p class="m-0 leading-20 text-15 black-color">
        @if (withdrawalForWeek !== null) {
          {{ withdrawalForWeek | currency: "KZT" : "symbol-narrow" : "0.2-2" }}
        } @else {
          {{ "-" }}
        }
      </p>
    </div>
    <div class="flex-1 flex justify-end">
      <p class="m-0 leading-20 text-15 black-color">
        @if (depositForWeek !== null) {
          {{ depositForWeek | currency: "KZT" : "symbol-narrow" : "0.2-2" }}
        } @else {
          {{ "-" }}
        }
      </p>
    </div>
  </div>
</div>
