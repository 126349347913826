import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[tabBarActiveLine]',
  standalone: true,
})
export class TabBarActiveLineDirective implements OnChanges {
  @HostBinding('style.width')
  public width?: string;

  @HostBinding('style.transform')
  public transform?: string;

  @Input()
  public tabBarActiveLine?: { button: DOMRect; parent: DOMRect };

  public ngOnChanges(_changes: SimpleChanges): void {
    const { button, parent } = this.tabBarActiveLine ?? {
      button: null,
      parent: null,
    };
    if (!button || !parent) {
      return;
    }
    this.transform = `translateX(${button.x - parent.x}px)`;
    this.width = `${button.width}px`;
  }
}
