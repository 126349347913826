import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProfileApiService } from '@common/services';
import {
  NotificationAdd,
  ProfileChangeCompany,
  ProfileChangeCompanyError,
  ProfileChangeCompanySuccess,
  ProfileLoad,
  ProfileLoadCompanies,
  ProfileLoadCompaniesError,
  ProfileLoadCompaniesSuccess,
  ProfileLoadError,
  ProfileLoadSuccess,
} from './actions';
import { catchError, combineLatestWith, exhaustMap, map, of } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class CabinetEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly api = inject(ProfileApiService);

  public readonly profileLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileLoad),
      exhaustMap(() =>
        this.api.load().pipe(
          combineLatestWith(this.api.loadEntity()),
          map(([user, entity]: any[]) =>
            ProfileLoadSuccess({
              email: user.email,
              activeCompanyId: user.activeCompanyId,
              entity,
            }),
          ),
          catchError(() => of(ProfileLoadError())),
        ),
      ),
    ),
  );

  public readonly loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileLoadCompanies),
      exhaustMap(() =>
        this.api.companies().pipe(
          map(({ items }: any) =>
            ProfileLoadCompaniesSuccess({ companies: items }),
          ),
          catchError(() => of(ProfileLoadCompaniesError())),
        ),
      ),
    ),
  );

  public readonly changeCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileChangeCompany),
      exhaustMap(({ id }) =>
        this.api.changeCompany({ activeCompanyId: id }).pipe(
          map(() => {
            window.location.reload();
            return ProfileChangeCompanySuccess();
          }),
          catchError((error) => {
            this.store.dispatch(NotificationAdd({ message: { error } }));
            return of(ProfileChangeCompanyError());
          }),
        ),
      ),
    ),
  );
}
