import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[tooltipTrigger]',
  standalone: true,
})
export class TooltipTriggerDirective {
  @HostListener('mouseenter')
  public mouseEnterHandler() {
    this.onHover.emit(true);
  }

  @HostListener('mouseleave')
  public mouseOutHandler() {
    this.onHover.emit(false);
  }

  @Output()
  public readonly onHover = new EventEmitter<boolean>();
}
