import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-loader-element',
  templateUrl: './loader-element.component.svg',
  styleUrls: ['./loader-element.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderElementComponent {}
