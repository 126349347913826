import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostListener,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LetDirective } from '@common/directives';

@Component({
  selector: 'lib-toggle',
  templateUrl: './lib-toggle.component.html',
  styleUrls: ['./lib-toggle.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LibToggleComponent),
      multi: true,
    },
  ],
  imports: [LetDirective],
})
export class LibToggleComponent implements ControlValueAccessor {
  public readonly value = signal<boolean>(false);

  public writeValue(val: boolean): void {
    this.value.set(val);
  }

  @HostListener('click', ['$event'])
  public onClick(): void {
    const status = !this.value();
    this.value.set(status);
    this.onChange(status);
  }

  onChange = (_: boolean) => {};

  onTouched = () => {};

  public registerOnChange(onChange: typeof this.onChange): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: typeof this.onTouched): void {
    this.onTouched = onTouched;
  }
}
