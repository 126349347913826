import { RegistryActionsEnum } from '@common/enums';

export const registryActionsMeta = new Map([
  [RegistryActionsEnum.Uploaded, { label: 'Загружен', color: '' }],
  [
    RegistryActionsEnum.SentToPayment,
    { label: 'Отправлен на выплату', color: 'green-color' },
  ],
  [RegistryActionsEnum.Deleted, { label: 'Удалён', color: 'red-color' }],
]);
