@for (item of list; track trackByFn; let first = $first) {
  <div
    class="flex"
    [class.ml-48]="!first"
    [tabBarIsActiveButton]="item.key"
    [key]="active()"
    (activePosition)="activePositionHandler($event)"
    (onClick)="activate(item.key)"
  >
    <p class="m-0 cursor-pointer leading-20 text-15 font-bold black-half-color">
      {{ item.value }}
    </p>
  </div>
}
<hr class="line" [tabBarActiveLine]="position()" />
