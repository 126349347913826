import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { concat, Observable, of } from 'rxjs';

@Directive({
  selector: '[inputUtils]',
  standalone: true,
})
export class InputUtilsDirective<T> {
  public readonly element: ElementRef<HTMLInputElement> = inject(
    ElementRef<HTMLInputElement>,
  );
  private readonly control = inject(NgControl);

  @Output()
  public readonly focusEvent = new EventEmitter<void>();

  @Output()
  public readonly blurEvent = new EventEmitter<void>();

  @HostListener('focus')
  public focusHandler(): void {
    this.focusEvent.emit();
  }

  @HostListener('blur')
  public blurHandler(): void {
    this.blurEvent.emit();
  }

  public click(): void {
    this.element?.nativeElement?.focus();
  }

  public getValue(): T;
  public getValue(async: true): Observable<T>;
  public getValue(async: false): T;

  public getValue(async?: boolean): Observable<T> | T {
    return async
      ? concat(
          of(this.control.value as T) as Observable<T>,
          this.control.valueChanges as Observable<T>,
        )
      : this.control.control?.value;
  }

  public get errors(): any {
    return this.control.touched ? this.control?.errors : null;
  }
}
